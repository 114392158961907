//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import {Chart} from 'highcharts-vue'
export default {
  name: "StoreDetail",
  components: {
    // highcharts: Chart
  },
  data() {
    return {
      startDate: "",
      endDate: "",
      store: "",

      chartOptions: {},
      list: [],
    };
  },
  mounted() {
    // window.document.title = '外部联系人报表';
  },
  activated() {
    // window.document.title = '外部联系人报表';
  },
  created() {
    window.document.title = this.$route.query.store;
    this.store = this.$route.query.store;
    this.startDate = this.$route.query.startDate;
    this.endDate = this.$route.query.endDate;

    this.getRf();
    this.getBehavior();
  },
  methods: {
    getRf() {
      const data = {
        pager: {
          pageIndex: 1,
          pageSize: 500,
          rowsCount: 0,
          start: 0,
        },
        queryMap: {
          shopCode: this.store,
          beginDate: this.startDate,
          endDate: this.endDate,
        },
        queryMapObject: {},
      };
      this.axios
        .post(
          "/" + this.$ajaxPrefix.consumer + "/rf/externalcontact/getrf",
          data
        )
        .then((res) => {
          console.log(res);
        });
    },
    getBehavior() {
      this.$toast.loading({
        message: "数据加载中",
        forbidClick: true,
        duration: 0,
      });
      const data = {
        pager: {
          pageIndex: 1,
          pageSize: 10,
          rowsCount: 0,
          start: 0,
        },
        queryMap: {
          shopCode: this.store,
          beginDate: this.startDate,
          endDate: this.endDate,
        },
        queryMapObject: {},
      };
      this.axios
        .post(
          "/" +
            this.$ajaxPrefix.consumer +
            "/rf/externalcontact/getUserBehaviorData",
          data
        )
        .then((res) => {
          console.log("详情", res);
          if (res && res.data && res.data.data) {
            const temp = res.data.data.behaviorList;
            temp.sort((a,b)=>{
                return b.statTime - a.statTime
            })
            temp.forEach((item) => {
              item.date = this.$moment(item.statTime * 1000).format(
                "MM-DD"
              );
              item.fullDate = this.$moment(item.statTime * 1000).format(
                "yyyy-MM-DD"
              );
            });
            this.list = temp;
          } else {
            this.list = [];
          }
          this.$toast.clear();
        });
    },
    formatWeek(i) {
      switch (i) {
        case 0:
          return "日";
        case 1:
          return "一";
        case 2:
          return "二";
        case 3:
          return "三";
        case 4:
          return "四";
        case 5:
          return "五";
        case 6:
          return "六";
      }
    },
  },
};
